<template>
  <v-row class="fill-height">
    <v-col>
      <v-row
          v-if="status === 'EXPIRED'"
      >
        <v-col v-if="!received">
          <v-row>
            <v-col
                class="text-center"
            >
              <h1>Time to refresh your link!</h1>
              <h2 class="mb-2">Your data is secure.</h2>
              <p>Click on the button below to request a new access link to your account. Your access expires every 24 hours, that way we can keep your information secure.</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                  block
                  :loading="isLoading"
                  :disabled="isLoading"
                  depressed
                  color="primary"
                  @click="submit"
                  x-large
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
            v-else
            class="text-center"
        >
          <h1>We sent you a message!</h1>
          <h2 class="mb-2">You are just one step away from your data.</h2>
          <p>You are going to receive a message from us withing the next hour, click on a link in that message to access your account.<br/>You can close this window.</p>
        </v-col>
      </v-row>
      <v-row
          v-else-if="status === 'PENDING'"
      >
        <v-col
            class="text-center"
        >
          <h1>We're taking you to your submission form!</h1>
        </v-col>
      </v-row>
      <v-row
          v-else-if="status === 'SUBMITTED'"
      >
        <v-col
            class="text-center"
        >
          <h1>We're taking you to your results page!</h1>
        </v-col>
      </v-row>
      <v-row
          v-else-if="status === 'UNINITIALIZED'"
      >
        <v-col
            class="text-center"
        >
          <h1>Welcome to {{ company.name }}!</h1>
          <h2 class="mb-2">You are just a little early.</h2>
          <p>Here we will display your symptom results once we’ve received updates. For now, we’re excited to have you on board!</p>
          <p>You are scheduled to receive a link to update your symptoms very soon. From there you will be able to see your results in the first text of the day and better navigate your symptoms.</p>
        </v-col>
      </v-row>
      <v-row
          v-else
      >
        <v-col
            class="text-center"
        >
          <h1>Welcome!</h1>
          <h2 class="mb-2">You reached the {{ company.name }}.</h2>
          <p>To access the patient portal, please click on a link in your text message (we embedded a special access code in that link).
          <br/>If you're still having issues please send a text message to +1 813 696 3814 or an email to <a :href="'mailto:' + company.contact">{{ company.contact }}</a>
            describing your problem.
          </p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Default',
  components: {},
  props: {
    company: {
      type: Object,
      default: () => {
        return {
          name: 'DayVue Patient Portal',
          contact: 'info@info@dayvue.health'
        }
      }
    },
  },
  data: () => ({
    isLoading: false,
    received: false
  }),
  computed: {
    ...mapGetters(['getStatus', 'getToken']),
    status () {
      if (this.getStatus) {
        if (this.getStatus.status) {
          return this.getStatus.status
        } else {
          return 'NO_STATUS'
        }
      } else {
        return 'NO_STATUS'
      }
    }
  },
  methods: {
    ...mapActions(['updateToken', 'setAlerts']),
    async submit () {
      if (this.getToken) {
        try {
          await this.updateToken(this.getToken)
          this.received = true
        } catch(error) {
          console.log(error)
          this.setAlerts({
            active: true,
            message: 'Requesting a token failed, we\'re having issues with our servers, please try again later.',
            color: 'error'
          })
        }
      } else {
        this.setAlerts({
          active: true,
          message: 'Requesting a token failed, we\'re having issues with our servers, please try again later.',
          color: 'error'
        })
      }
    }
  },
};
</script>
